import React from 'react';
import ReactDOM from 'react-dom';
import Panel from "./config.jsx";
import { ConfProvider } from './data.jsx';

    
ReactDOM.render((
  <ConfProvider>
    <Panel />
  </ConfProvider>
), document.getElementById('root'));

// Custom hooks that wrap contextual states
import React, { useState, useEffect, createContext, useContext } from 'react';

const BLOCKS = {
  Sun: {
    'pre-songs':   '诗歌之前',
    'songs':       '诗歌',
    'verses':      '经文与证道',
    'post-verses': '证道之后',
  },
  Fri: {
    'songs':       '诗歌',
  },
}

const DEFAULTID = 'DEFAULT'

const _initBlocks = (fm) => Object.fromEntries(Object.entries(BLOCKS[fm]).map(([k,v],i) => [k,{
  label: v,
  owner: DEFAULTID,
  no: i,
}]))

// styleClasses like 'sc-dark' are implicit. To set defaults see `useSects`
const STYLEC = {
  'sc-text-shadow':   '文字阴影',
  'sc-cont-backdrop': '文字衬板',
}

// [ { id: ID, styleClass: []str, src?: str, color?: str } ] where typeof ID = str
const SectsCtxt = createContext([[], ()=>[]])
// { [str]: { label: str, owner: ID, no: int } }
const BlocksCtxt = createContext([{}, ()=>{}])

const FormCtxt = createContext(['', ()=>''])

function useForm() {
  const [form, setForm] = useContext(FormCtxt)
  const { initBlocks } = useBlocks()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(()=> initBlocks(form), [form])
  return {
    form,
    setForm,
  }
}

const ConfProvider = (props) => {
  return (
    <FormCtxt.Provider value={useLocalStorage('form', 'Sun')}>
    <SectsCtxt.Provider value={useState([])}>
    <BlocksCtxt.Provider value={useState({})}>
      {props.children}
    </BlocksCtxt.Provider>
    </SectsCtxt.Provider>
    </FormCtxt.Provider>
  );
}


function useSects() {
  // A section of style shares a BGImage and styles
  const [sects, setSects] = useContext(SectsCtxt);
  const { blocks, rmvLbl } = useBlocks();
  const addSec = () => {
    setSects([
      ...sects,
      {
        id: Math.random().toString(36).substr(2,5),
        styleClass: ['sc-text-shadow'],
      }
    ])
  }
  const rmvSec = (sid) => {
    rmvLbl(...Object.entries(blocks).filter(([_,b])=>b.owner===sid).map(([bn,_])=>bn))
    setSects(sects.filter(s=>s.id!==sid))
  }
  const setOpt = (i, data) => {
    setSects((sects) => sects.map((e,j)=>
      i===j ? { ...e, ...data } : e
    ))
  }

  return {
    sects,
    addSec,
    rmvSec,
    setOpt,
  }
}

function useBlocks() {
  // The groups of pages available for ownering styles
  const [blocks, setBlocks] = useContext(BlocksCtxt);
  const initBlocks = (form) => {
    setBlocks(_initBlocks(form))
  }
  const addLbl = (sid, ...bns) => {
    setBlocks({ // (blocks[bn].owner = sid) for bn in bns
      ...blocks,
      ...Object.fromEntries(bns.map(bn=>[bn, {...blocks[bn], owner: sid}])),
    })
  }
  const rmvLbl = (...bns) => addLbl(DEFAULTID, ...bns)

  return {
    blocks,
    initBlocks,
    addLbl,
    rmvLbl,
  }
}

// adapted from https://github.com/streamich/react-use/blob/master/src/useLocalStorage.ts
function useLocalStorage(key, initValue) {
  const [state, setState] = useState(() => {
    try {
      const storedValue = localStorage.getItem(key);
      if (typeof storedValue !== 'string') {
        localStorage.setItem(key, initValue)
        return initValue
      } else {
        return storedValue
      }
    } catch { // fallback
      return initValue
    }
  })
  
  useEffect(() => { // debounced save
    const handle = setTimeout(() => {
      try { localStorage.setItem(key, state) } catch {}
    }, 2000)
    return () => { clearTimeout(handle) }
  }, [key, state]);

  return [state, setState]
}

export {
  useSects,
  useBlocks,
  useForm,
  useLocalStorage,
  ConfProvider,
  STYLEC,
}

import React, { useState, memo } from 'react';
import { useSects, useBlocks, useForm, useLocalStorage, STYLEC } from './data.jsx';
import { Pending } from './deploy.jsx'
import { Guide } from './cheatsheet.jsx'
import Styles from "./config.module.css";

const TIPS = [
  "如果不为一组页面指定背景，将会默认使用与封面封底一样的那张沙洲背景。",
  "放映时可按 f 键切换全屏",
  "除了几个被赋予特殊含义的符号外( ---、>、#、作为列表的 */+/- )，Slides 的内容支持 Markdown 格式。",
  "上传的背景图片总和建议不要超过 5MB 。",
  "匹配放映设备的背景图片最佳长宽比为 16:9 。",
  "Chrome 前往 chrome://flags/#enable-experimental-web-platform-features 启用，可实现在放映中阻止屏幕进入睡眠（实验性功能，请谨慎使用）。",
]

const DEFAULTID = 'DEFAULT'
const TEXTPREVIEW = (<>
<p>一小段普通的预览文字，</p>
<p>默默填补着页面的空白。</p>
<p>以其寥寥数行，</p>
<p>努力模仿着实际的样式。</p>
</>)


const vnoAscend = ([_,x],[__,y]) => x.no-y.no // sort to maintain blocks' order

// A Pallet provides label candidates to the section.
function Pallet({ sty, sid, isAdv, styCls, togStyCls }) {
  const { blocks, addLbl, rmvLbl } = useBlocks()
  const togLbl = (bn) => blocks[bn].owner===sid ? rmvLbl(bn) : addLbl(sid, bn)
  const lbl = Object.entries(blocks)
                    .sort(vnoAscend)
  isAdv = false // FIXME
  return (
    <div className={Styles.pallet+' '+sty}>
      {!isAdv ? <>
          将此背景用于：
        {lbl.map(([bn,b]) =>
          <div key={bn}   // unclickable if hide
               onClick={()=> sty && togLbl(bn)}
               className={Styles.candidate+' '+(b.owner!==DEFAULTID?Styles.owned:'')}>
            {b.label}
          </div>
        )}
        {lbl.length>1 &&
          <div onClick={() => addLbl(sid, ...Object.keys(blocks))}
               style={{ background: 'none', color: 'black' }}
               className={Styles.candidate}>
            我全都要!
          </div>
        }
      </> : <>
          枯燥
      </>}
    </div>
  );
}

// a Label selected for the section, assigning a group of pages to the section
function Label({ text, isDefault, bn }) {
  const { rmvLbl } = useBlocks()
  return (
    <div className={Styles.label+' '+(isDefault?Styles.owned:'')}>
        <span>{text}</span>
      {!isDefault &&
        <div onClick={()=> rmvLbl(bn)}
             style={{ top: '-.15em' }}
             className={Styles.del+' '+Styles.wid+' '+Styles.dot}>
          {iconMinus}
        </div>
      }
    </div>
  );
}

// A Section consists of a background image and text styles for one or more groups of pages.
function Section({ sid, text, isDefault, purl='', styCls, storeImg, remove }) {
  const { blocks } = useBlocks()
  const lbls = Object.entries(blocks)
                     .filter(([_,b])=>b.owner===sid)
                     .sort(vnoAscend)
  const [previewURL, setPURL] = useState(purl)
  const [previewColor, setPColor] = useState('')
  const togStyCls = (c, on) => {
    const nc = on ? [...styCls.filter(x=> x!==c), c] : styCls.filter(x=> x!==c)
    storeImg({ styleClass: nc })
  }
  const preview = async (e) => {
    const newImg = e.target.files && e.target.files[0]
    const value = e.target.value
    let isDark;
    if (newImg) {
      const url = window.URL.createObjectURL(newImg)
      isDark = await calcImg(url)
      setPURL(url)
      storeImg({ src: newImg.name })
      togStyCls('sc-dark', isDark)
    } else if (value) { // color
      isDark = calcColor(hex2rgb(value))
      setPColor(value)
      storeImg({ color: value })
      togStyCls('sc-dark', isDark)
    }
  }
  const styPreview = previewURL ? { backgroundImage: `url(${previewURL})` } : (
                     previewColor ? { backgroundColor: previewColor } : {} )

  const prompTxt = <p>选择<b>图片</b>或<b>色彩</b></p>
  const [advancePal, setAdvancePal] = useState(false)
  const togglePal = () => setAdvancePal(s=>!s)
  const showPal = previewURL||previewColor ? (lbls.length===0 ? Styles.stay : Styles.blink) : ''
  return (
    <section style={styPreview} className={styCls.map(c=>Styles[c]).join(' ')}>
        <div className={Styles.labelbox}>
          {lbls.map(([bn,b]) =>
            <Label key={bn}
                   bn={bn}
                   text={b.label}
                   isDefault={isDefault} />
          )}
        </div>
        <span>{isDefault||previewURL||previewColor ? text : prompTxt}</span>
      {!isDefault && <>
        <div>
          {!previewColor && <input type='file' accept='image/*' name={sid} id={sid} onChange={preview} />}
          {!previewURL   && <input type='color' name={`c-${sid}`} id={`c-${sid}`} onChange={preview} onInput={preview} />}
        </div>
        <div onClick={togglePal} className={Styles.adv+' '+Styles.wid+' '+Styles.dot+' '+showPal}>
          {iconSetting}
        </div>
        <div onClick={remove} className={Styles.del+' '+Styles.wid+' '+Styles.dot}>
          {iconMinus}
        </div>
        <Pallet sid={sid}
                sty={showPal}
                isAdv={advancePal}
                styCls={styCls}
                togStyCls={togStyCls} />
      </>}
    </section>
  );
}

// The Style Config is the subpanel on the right that manages multiple sections.
function StyleConf() {
  const { sects, addSec, rmvSec, setOpt } = useSects()
  return (
    <div>
      <Section key={DEFAULTID}
               sid={DEFAULTID}
               isDefault={true}
               purl='https://olive-static.now.sh/sand.jpeg'
               text="封面封底及默认的样式"
               styCls={[]}
      />
    {sects.map((s, i) => 
      <Section key={s.id}
               sid={s.id}
               text={TEXTPREVIEW}
               styCls={s.styleClass}
               storeImg={setOpt.bind(null, i)}
               remove={rmvSec.bind(null, s.id)}
      />
    )}
      <div onClick={addSec}
           className={Styles.ins+' '+Styles.dot}>
        添加背景
      </div>
    </div>
  );
}

const ProgramEditor = memo(() => {
    const [ program, saveProgram ] = useLocalStorage('program', '')

    return <textarea name="program"
                     onKeyDown={allowTab}
                     defaultValue={program} onChange={(e)=> saveProgram(e.target.value)}
                     placeholder="要不，先看看右边的指南吧"></textarea>
})

function FormSwitcher() {
  const { form, setForm } = useForm()

  return <div className={Styles.choices+' '+Styles.formtoggle}>
     <span className={form==='Fri'?Styles.chosen:''} onClick={()=>setForm('Fri')}>周五</span>{" / "}
     <span className={form==='Sun'?Styles.chosen:''} onClick={()=>setForm('Sun')}>周日</span>
  </div>
}

export default function Panel() {
  const [isPending, watchDeployment] = useState(false)
  const submit = (e) => {
    e.preventDefault()
    watchDeployment(true)
    let formData = new FormData(e.target);

    let empty = []
    for(let [k,v] of formData.entries()) {
      if (v instanceof Blob && v.size === 0) empty.push(k)
    }
    empty.forEach(k=>formData.delete(k))

    formData.append("options", JSON.stringify(serialize()));

    let request = new XMLHttpRequest();
    request.open("POST", "/api/deploy");
    request.send(formData);
  }

  const { sects } = useSects()
  const { blocks } = useBlocks()
  const { form } = useForm()
  const serialize = () => {
    const secMap = Object.fromEntries(sects.map(s=>[s.id, s]))
    return {
      form,
      style: Object.fromEntries(
        Object.entries(blocks)
              .filter(([_,b])=>b.owner!==DEFAULTID)
              .map(([bn,b])=>[bn,{ ...secMap[b.owner] }])
      ),
    }
  }

  return (
    <form encType="multipart/form-data" action="/" method="post" onSubmit={submit}>
        <StyleConf />
        <div>
          <FormSwitcher />
          <ProgramEditor />
          <button type="submit">生成(大约需要 1 分钟)</button>
        </div>
        <Guide form={form} />
      {isPending &&
        <Pending tips={TIPS[Math.floor(Math.random() * TIPS.length)]}
                 close={()=> watchDeployment(false)} />
      }
    </form>
  );
}

const allowTab = (e) => { // allows Tab to be inserted in the textarea
  if (e.keyCode === 9 || e.which === 9) {
    e.preventDefault();
    let el = e.target
    const s = el.selectionStart;
    el.value = el.value.substring(0,s) + "\t" + el.value.substring(el.selectionEnd);
    el.selectionEnd = s + 1;
  }
}

function loadImg(url) {
  return new Promise((resolve, reject) => {
    let img = new Image()
    img.onload = () => {
      let canvas = document.createElement('canvas')
      let context = canvas.getContext && canvas.getContext('2d')
      canvas.height = img.naturalHeight || img.offsetHeight || img.height
      canvas.width = img.naturalWidth || img.offsetWidth || img.width
      context.drawImage(img, 0, 0)
      resolve([context, { width: canvas.width, height: canvas.height }])
    }
    img.src = url
  })
}
async function calcImg(url) { // determine whether an image is in light theme or dark
  let [context, pos] = await loadImg(url)
  let data = context.getImageData(0, 0, pos.width, pos.height)
  let i = -4,
      r = 0, g = 0, b = 0,
      count = 0
  const blockSize = 5 // only visit every 5 pixels
  const length = data.data.length
  while ((i += blockSize * 4) < length) {
      ++count
      r += data.data[i]
      g += data.data[i+1]
      b += data.data[i+2]
  };
  [r, g, b] = [r/count, g/count, b/count]
  return calcColor({ r, g, b })
}
function calcColor({ r, g, b }) {
  const o = Math.round((r*299 + g*587 + b*114) / 1000)
  const isDark = o <= 125
  return isDark
}
function hex2rgb(h) {
  const v = parseInt(h.slice(1), 16);
  return { r: (v>>16)&255, g: (v>>8)&255, b: v&255 }
}

const iconSetting = <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50"><path d="M47.16,21.221l-5.91-0.966c-0.346-1.186-0.819-2.326-1.411-3.405l3.45-4.917c0.279-0.397,0.231-0.938-0.112-1.282 l-3.889-3.887c-0.347-0.346-0.893-0.391-1.291-0.104l-4.843,3.481c-1.089-0.602-2.239-1.08-3.432-1.427l-1.031-5.886 C28.607,2.35,28.192,2,27.706,2h-5.5c-0.49,0-0.908,0.355-0.987,0.839l-0.956,5.854c-1.2,0.345-2.352,0.818-3.437,1.412l-4.83-3.45 c-0.399-0.285-0.942-0.239-1.289,0.106L6.82,10.648c-0.343,0.343-0.391,0.883-0.112,1.28l3.399,4.863 c-0.605,1.095-1.087,2.254-1.438,3.46l-5.831,0.971c-0.482,0.08-0.836,0.498-0.836,0.986v5.5c0,0.485,0.348,0.9,0.825,0.985 l5.831,1.034c0.349,1.203,0.831,2.362,1.438,3.46l-3.441,4.813c-0.284,0.397-0.239,0.942,0.106,1.289l3.888,3.891 c0.343,0.343,0.884,0.391,1.281,0.112l4.87-3.411c1.093,0.601,2.248,1.078,3.445,1.424l0.976,5.861C21.3,47.647,21.717,48,22.206,48 h5.5c0.485,0,0.9-0.348,0.984-0.825l1.045-5.89c1.199-0.353,2.348-0.833,3.43-1.435l4.905,3.441 c0.398,0.281,0.938,0.232,1.282-0.111l3.888-3.891c0.346-0.347,0.391-0.894,0.104-1.292l-3.498-4.857 c0.593-1.08,1.064-2.222,1.407-3.408l5.918-1.039c0.479-0.084,0.827-0.5,0.827-0.985v-5.5C47.999,21.718,47.644,21.3,47.16,21.221z M25,32c-3.866,0-7-3.134-7-7c0-3.866,3.134-7,7-7s7,3.134,7,7C32,28.866,28.866,32,25,32z" fill="#fff"/></svg>
const iconMinus = <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M0 10h24v4h-24z" fill="#fff"/></svg>

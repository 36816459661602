import React, { useState, useEffect } from 'react';
import Styles from "./config.module.css";

const ENDPOINT = '/api'
const ESTICOMP = 60
const timenow = () => Math.floor(Date.now() / 1000)

const query = (path, { method='GET' }={}) => fetch(ENDPOINT+path, {
  mode: 'cors',
  method: method,
}).then(r=> r.ok ? r.json() : { error: r.statusText }).catch(e=> ({ error: e }))

const checkStatus = (ect, cb) =>
  setTimeout(async () => {
    const { status } = await query('/status')
    console.log('status:', status)
    if (status === 'READY') {
      await query('/alias', { method: 'POST' })
      console.log('aliased')
      cb()
    } else { // TODO: status === 'INITIALIZING' / 'ERROR'
      checkStatus(ect/2, cb)
    }
  }, Math.max(ect/2, 10)*1000)

function Pending({ tips, close }) {
  const [time, setTime] = useState(ESTICOMP)
  useEffect(()=>{
    const ect = timenow() + ESTICOMP
    const clock = setInterval(() => {
      let t = ect - timenow()
      if (t<=0) {
        t = 0
        clearInterval(clock)
      }
      setTime(t)
    }, 1000)
    checkStatus(ESTICOMP, ()=> {
      clearInterval(clock)
      setTime(-1)
    })
    return () => clearInterval(clock)
  }, [])
  return (
  <div className={Styles.pending}>
    <div>
      {time > 0
        ? <>
          <p>生成中……<br/><progress value={(ESTICOMP-time)/ESTICOMP}></progress></p>
          <p>Tips: {tips}</p>
          </>
        : (
       time === 0
        ? <p>还需稍等片刻。若等待时间过长，请联系 Harry 。</p>
        : <>
          <p>部署完毕！请前往 <a href='https://bccc.now.sh' target='_blank' rel='noopener noreferrer'>bccc.now.sh</a> 查看。</p>
          <p className={Styles.ins+' '+Styles.dot} onClick={close}>返回修改</p>
          </>
        )
      }
    </div>
  </div>
  );
}

export { Pending }

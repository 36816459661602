import React, { useState, memo } from 'react';
import Styles from "./cheatsheet.module.css";

const Guide = memo(({ form }) => {
  const [showing, toggle] = useState(false);
  return (<>
    <div className={Styles.dot+' '+Styles['guide-button']}
         onClick={()=> toggle(!showing)}>?</div>
    <div className={Styles.guide+' '+(showing?Styles.show:'')}>
      <div className={Styles.dot+' '+Styles.close}
           onClick={()=> toggle(false)}>{iconClose}</div>
      <p>页面右侧的文本框用于编辑幻灯片的内容，请将经文章节、诗歌、讲道等按顺序一并填入，加上少许标记即可被生成器理解。简单地来说，这些标记如下：</p>
      <div className={Styles.brief}>
        {Brief[form]}
      </div>
      <p>上面的描述太过简略，我们来看一个完整的例子：</p>
      {Sample[form]}
    </div>
  </>);
})

export { Guide }


const Song = (
<pre>{`# 诗歌标题
副标题（可选）

歌词第一页

歌词第二页`}</pre>
)
const Verse = (
<pre>{`> 经文章节`}</pre>
)
const Sermon = (
<pre>{`# 讲道标题
* 大纲第一点
	* 第一小点
* 第二点`}</pre>
)
const Group = (
<pre>{`* 小组1名称: 带领者1（可选）
* 小组2名称: 带领者2（可选）`}</pre>
)

const Brief = {
  Sun: <>
       {Song}
       {Verse}
       {Sermon}
       </>,
  Fri: <>
       {Song}
       {Group}
       </>,
}


function Hint({ text }) {
  return (
    <span style={{ height: 0, width: 0, position: 'absolute' }}>
      <span className={Styles.dot+' '+Styles.tag}>?</span>
      <span className={Styles.explain}>{text}</span>
    </span>
  );
}

const Sample = {
  Sun: (
<pre>{`> 诗23`}<Hint text="经文只需列明章节（更多的例子在后面）即可，生成器会负责查询经节和分页。写在开头的经节会被识别为「呼召经文」。" />{`


# 坐在寶座上聖潔羔羊

坐在寶座上聖潔羔羊，
我們俯伏敬拜你，
昔在今在以後永在，
唯有你是全能真神；

坐在寶座上尊貴羔羊，
我們俯伏敬拜你，
頌讚尊貴榮耀權勢，
都歸給你直到永遠！

萬王之王，萬主之主，
唯有你配得敬拜和尊崇，
萬王之王，萬主之主，
我們高舉你聖名直到永遠。
`}<Hint text="所有这些空行都是必须的，它们告诉生成器“在这里分页”。空行的行数随意。" />{`

# 住在你裡面
讚美之泉

在乾旱無水之地我渴慕你，
在曠野無人之處我尋求你，
得救在乎歸回安息，
得力在乎平靜平穩，
我等候你，如鷹展翅上騰。
(2X)

住在你裡面，住在你裡面，
如同枝子與葡萄樹緊緊相連，
住在你裡面，住在你裡面，
領受生命活水泉源永不枯竭。
(2X)


> 約13:31-35`}<Hint text="这一部分是讲道的经文，像这样的格式都可以被接受：弗2、弗2:10、弗2:4-10,13,19-21。书卷的名称也可以写全称。跨越章的选段请分成两条来写。" />{`

> 徒11:1-18

> 啟21:1-6


# 门徒在主的去回之间……
* 现时相爱：约13:31-35
	* 一条相爱的新命令`}<Hint text="二级列表前加一个 Tab （而不是空格）" />{`
	* 相爱见证是主门徒
	* 主自己作爱的榜样
* 突破旧观：徒11:1-18
	* 正视冲突
	* 突破观念
	* 看到异象
	* 圣灵动工
	* 同心宣道
* 盼望未来：启21:1-6
	* 新天地
	* 新圣城
	* 新关系
	* 新开始
	* 新供应


# 住在你裡面`}<Hint text="文末的一首诗歌会被识别为「回应诗歌」。" />{`

住在你裡面，住在你裡面，
如同枝子與葡萄樹緊緊相連，
住在你裡面，住在你裡面，
領受生命活水泉源永不枯竭。
(2X)`}</pre>
  ),


  Fri: (
<pre>{`# 坐在寶座上聖潔羔羊

坐在寶座上聖潔羔羊，
我們俯伏敬拜你，
昔在今在以後永在，
唯有你是全能真神；

坐在寶座上尊貴羔羊，
我們俯伏敬拜你，
頌讚尊貴榮耀權勢，
都歸給你直到永遠！

萬王之王，萬主之主，
唯有你配得敬拜和尊崇，
萬王之王，萬主之主，
我們高舉你聖名直到永遠。
`}<Hint text="所有这些空行都是必须的，它们告诉生成器“在这里分页”。空行的行数随意。" />{`

# 住在你裡面
讚美之泉

在乾旱無水之地我渴慕你，
在曠野無人之處我尋求你，
得救在乎歸回安息，
得力在乎平靜平穩，
我等候你，如鷹展翅上騰。
(2X)

住在你裡面，住在你裡面，
如同枝子與葡萄樹緊緊相連，
住在你裡面，住在你裡面，
領受生命活水泉源永不枯竭。
(2X)

* 學生組查經: 顧林
* 家庭組查經: 邱傳道
* 英文組
* 福音組: 樊紅姐`}</pre>
  ),
}

const iconClose = <svg viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg"><path d="M38 12.83l-2.83-2.83-11.17 11.17-11.17-11.17-2.83 2.83 11.17 11.17-11.17 11.17 2.83 2.83 11.17-11.17 11.17 11.17 2.83-2.83-11.17-11.17z" fill="#fff"/><path d="M0 0h48v48h-48z" fill="none"/></svg>;
